<template>
  <div class="point flex flex-row relative" :class="[props.type]">
    <div class="flex pr whitespace-nowrap">
      <div v-if="label">
        {{ props.label }}
      </div>
    </div>
    <div :class="[props.type] + '-text'" class="flex justify-end items-center flex-1">
      <div :class="[props.diamondShop ? 'pr' : '']" class="text">
        {{ props.value.toLocaleString() }}
      </div>
    </div>
    <NuxtLink v-if="visibleShopBtn()" no-prefetch class="items-center dia-shop-btn" @click="onClickAddPoint" />
  </div>
</template>

<script lang="ts" setup>
import { navigateToPurchasePage } from '~/composables/useShop';
import { useSe } from '~/composables/useAudio';

interface Props{
  value: number,
  type: 'coin' | 'gesocoin' | 'dia',
  label?: string,
  diamondShop?: boolean,
}
const props = withDefaults(defineProps<Props>(), {
  label: '',
  diamondShop: false,
});

interface Emits {
  (e: 'onAddPoint'): void;
}
const emit = defineEmits<Emits>();

const visibleShopBtn = () => {
  return props.diamondShop;
};

const okSe = useSe(0, '2001');
const onClickAddPoint = async () => {
  okSe?.play();
  emit('onAddPoint');
  await navigateToPurchasePage();
};
</script>

<style lang="scss" scoped>
.point {
  color: #282d44;
  font-size: 0.8rem;
  padding: 2px 10px 2px 4px;
  border-image: url("~/assets/images/common/common_status_bg_2.png") 15 15 15 15 fill/ 5px 5px 5px 5px stretch;

  &::before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: center no-repeat;
    background-size: 100%;
    margin-right: 3px;
  }

  &.dia {
    &::before {
      background-image: url("~/assets/images/common/common_icon_dia.png");
    }
  }

  &.coin {
    &::before {
      background-image: url("~/assets/images/common/common_icon_coin.png");
    }
  }

  &.gesocoin {
    &::before {
      background-image: url("~/assets/images/common/common_icon_gesocoin.png");
    }
  }

  .pr {
    margin-right: 20px;
  }

  .text {
    color: #282d44;
  }
}

.dia-shop-btn {
  position: absolute;
  right: 0;
  top: -0.2px;
  width: 24px;
  height: 24px;
  background: url("~/assets/images/common/common_btn_plus.png") center no-repeat;
  background-size: contain;
}
</style>
